import React,{useState} from 'react'
import ImageGrid from './ImageGrid';
import Modal from './Modal';
import Title from './Title';
import UploadForm from './UploadForm';
import '../styles/index.css';
import Navbar from 'components/Navbar';
const ImageDetails = props => {
  const [selectedImg, setSelectedImg] = useState(null);
    return (
      <>
    <Navbar transparent/>

     <Title imageType={props.match.params.id}/>
     <div className="customApp">
      {/* <UploadForm imageType={props.match.params.id} /> */}
      <ImageGrid imageType={props.match.params.id} setSelectedImg={setSelectedImg} />
      { selectedImg && (
        <Modal selectedImg={selectedImg} setSelectedImg={setSelectedImg} />
      )} 
        </div>
      </>

    )
}

export default ImageDetails;
