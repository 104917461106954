import React from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";

export default function Profile() {
  const latLng = {
    lat:'22.6197117',
    lng:'88.4425421'
  }
  const showInMapClicked = () => {
    try {
    window.open("https://maps.google.com?q="+latLng.lat+","+latLng.lng );
    } catch (error) {
      
    }
  };
  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block" style={{ height: "1400px" }}>
          <div className="backgroundProfile absolute top-0 w-full h-full bg-center bg-cover">
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-16 bg-gray-300">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-12/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="..."
                        src={
                          require("assets/img/ArpitaProfileAvatar1.png").default
                        }
                        className="avatar shadow-xl rounded-full h-auto align-middle border-none  -m-20 -ml-20 lg:-ml-16"
                        style={{ maxWidth: 200, borderRadius: "100%",
                        justifyContent: 'center', alignItems: 'center',
                        width: 200,
                        height: 200,
                        borderRadius: '50%',
                      }}
                      />
                    </div>
                  </div>
                  {/* <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                    <div className="py-6 px-3 mt-32 sm:mt-0">
                      <button
                        className="bg-pink-500 active:bg-pink-600 uppercase text-white font-bold hover:shadow-md shadow text-xs px-4 py-2 rounded outline-none focus:outline-none sm:mr-2 mb-1"
                        type="button"
                        style={{ transition: "all .15s ease" }}
                      >
                        Connect
                      </button>
                    </div>
                  </div> */}
                  {/* <div className="w-full lg:w-3/12 px-4 lg:order-1">
                    <div className="flex justify-center py-4 lg:pt-4 pt-8">
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          22
                        </span>
                        <span className="text-sm text-gray-500">Friends</span>
                      </div>
                      <div className="mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          10
                        </span>
                        <span className="text-sm text-gray-500">Photos</span>
                      </div>
                      <div className="lg:mr-4 p-3 text-center">
                        <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                          89
                        </span>
                        <span className="text-sm text-gray-500">Comments</span>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="text-center mt-12" style={{ marginTop: 90 }}>
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                    Arpita Manna
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500" style={{cursor:'pointer'}} onClick={showInMapClicked}></i>{" "}
                    Chinarpark, Kolkata
                  </div>
                  <div className="mb-2 text-gray-700">
                    <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                    Makeup Artist - Arpita's Makeover
                  </div>
                  <div className="mb-2 text-gray-700">
                    <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                    Vidyasagar University
                  </div>
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800" style={{fontFamily:'Times New Roman'}}>
                        I am Arpita, having 5 years of experience in makeup
                        industry.I provide end to end service like Bridal, Reception, Party, Western, Hairstyle and all. Makeup is not, as often believed, about making
                        you look plastic. It is, rather about accentuating one’s
                        natural beauty. It is an underappreciated art form that
                        is finally getting the recognition it deserves and I help bring one’s beauty to the fore. From
                        advising what products to use to decking you up for your
                        special events, I help you with all your
                        grooming needs. 
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
