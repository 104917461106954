import React from "react";
import { withRouter } from "react-router-dom";

const PageTitle = (props) => {
  const navigateToLanding = () => {
    props.history.push("/");
  };

  return (
    <div className="title cursor-pointer" onClick={navigateToLanding}>
      <div style={{width:180}}>
      <img
        // src="https://github.com/shyamalVixplor/MyPersonalImages/blob/main/amy-shamblen-xwM61TPMlYk-unsplash.jpg?raw=true"
        src={require("assets/img/brandLogo1.png").default}
        alt="img"
        className="cursor-pointer" 
        style={{ maxWidth: "250px", alignSelf:'center',backgroundColor:'transparent' }}
        onClick={navigateToLanding}
      />
      </div>
    </div>
  );
};

export default withRouter(PageTitle);
