import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'



const configDataSlice = createSlice({
    name: 'appConfig',
    initialState: { 
        landingDesc: `Welcome to Arpita's Makeover. I am a professional makeup
                    artist. My main motive is to provide the best makeup within
                    a less time & budget.`,
    makeupAbout:'',
    },
    reducers: {
      getAbout(state, action) {
        state.makeupAbout = action.payload
      },
    },
  })
  
  
  export const { getAbout } = configDataSlice.actions
  export default configDataSlice;
  