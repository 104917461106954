import React, { useState } from 'react';
import { BrowserRouter,Switch,Route } from 'react-router-dom'
import Landing from 'views/Landing';
import ImageDetails from 'comps/ImageDetails';
import PageNotFound from 'views/PageNotFound';
import { DemoCarousel } from 'components/Carousel';
import Profile from 'views/Profile';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
    <Switch>
       <Route exact path='/' component={Landing}/>
       <Route exact path='/profile' component={Profile}/>
       <Route exact path='/imageDetails/:id' component={ImageDetails}/>
       <Route path='*' component={PageNotFound}/>
    </Switch>
    </div>
    </BrowserRouter>
  );
}

export default App;
