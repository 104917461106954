import React from 'react';
import useFirestore from '../hooks/useFirestore';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Navbar from 'components/Navbar';

const ImageGrid = ({ setSelectedImg, imageType }) => {
  const { docs } = useFirestore(imageType);
  return (
    <>
    <div className="img-grid">
      {docs && docs.map((doc,i) =>{
        return(
        <motion.div className="img-wrap" key={doc.id} 
          layout
          whileHover={{ opacity: 1 }}
          onClick={() => setSelectedImg(doc.url)}
        >
          <LazyLoadImage 
          src={doc.url}
          effect="blur"
          key={i}
          className="img"
          height={293}
          width={293}
          placeholderSrc={doc.url}
          />
          {/* <img src={doc.url} alt="uploaded pic"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            loading="lazy"
          /> */}
        </motion.div>
      )} 
      )}
    </div>
    </>
  )
}

export default ImageGrid;