import React from "react";
import { DemoCarousel } from "./Carousel";

const CustomerReview = () => {
  return (
    // <section class="mb-20 text-gray-700" style={{paddingLeft:150, paddingRight:150}}>
    //   <div class="text-center md:max-w-xl lg:max-w-3xl mx-auto">
    //     <h3 class="text-3xl font-bold mb-6 text-gray-800">Customer Review</h3>
    //     <p class="mb-6 pb-2 md:mb-12 md:pb-0">
    //       Customers have a lot to share about how they use my
    //       service and how it has benefitted to them. In addition, reviews from
    //       these loyal customers can be beneficial to potential new users.
    //     </p>
    //   </div>
    //   <DemoCarousel />
    // </section>
    <section>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-center text-center mb-24">
          <div className="w-full lg:w-6/12 px-4">
            <h2 class="text-3xl font-bold mb-6 text-gray-800">
              Customer Review
            </h2>
            <p class="mb-6 pb-2 md:mb-0 md:pb-0">
              Customers have a lot to share about how they use my service and
              how it has benefitted to them. In addition, reviews from these
              loyal customers can be beneficial to potential new users.
            </p>
          </div>
        </div>
        <DemoCarousel />
      </div>
    </section>
  );
};

export default CustomerReview;
