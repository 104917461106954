import PageTitle from "comps/PageTitle";
import React from "react";
import { goToFacebook, goToInstagram } from "./Footer";


export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [isDesktop, setDesktop] = React.useState(window.innerWidth > 1450);
  const goToCall = () =>{
    window.open('tel:9932142591');
  }
  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };


  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
console.log("isDesktop", isDesktop);
  return (
    <nav
      className={
        (props.transparent
          ? "top-0 absolute z-50 w-full"
          : "relative shadow-lg bg-white shadow-lg") +
        " flex flex-wrap items-center justify-between px-2 py-3 "
      }
    >
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <PageTitle/>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            <i
              className={
                (props.transparent ? "text-red-200" : "text-gray-800") +
                " fas fa-bars"
              }
            ></i>
          </button>
        </div>
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" +
            (navbarOpen ? " block rounded shadow-lg" : " hidden")
          }
          id="example-navbar-warning"
        >
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                onClick={goToFacebook}
                // href="https://www.facebook.com/profile.php?id=100090420534325&mibextid=ZbWKwL"
                style={!isDesktop ?styles.iconCursor: styles.iconBackGround}
              >
                <i
                  className={
                    "text-blue-500 fab fa-facebook text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Facebook</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-gray-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                // href="#pablo"
                onClick={goToInstagram}
                style={!isDesktop ?styles.iconCursor: styles.iconBackGround}
              >
                <i
                  className={
                    "text-orange-500 fab fa-instagram text-lg leading-lg "
                  }
                />
                <span className="lg:hidden inline-block ml-2">Instagram</span>
              </a>
            </li>

            <li className="flex items-center">
              <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-orange-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                href="/profile"
                style={!isDesktop ?styles.iconCursor: styles.iconBackGround}
              >
                {/* <span style={{fontWeight:700, fontSize:12, marginLeft: 8}}>About</span> */}
               {/* {props.transparent ?  */}
               <i class="text-orange-500 fa-solid fa-user text-lg leading-lg"/>

              {!isDesktop && <span className="lg:hidden inline-block ml-2">My Profile</span>}
                
              </a>
            </li>

            <li className="flex items-center">
                 <a
                className={
                  (props.transparent
                    ? "lg:text-white lg:hover:text-gray-300 text-gray-800"
                    : "text-orange-800 hover:text-gray-600") +
                  " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                }
                onClick={goToCall}
                style={!isDesktop ?styles.iconCursor: styles.iconBackGround}
              >
                <i style={{fontSize:16}} className="text-orange-500 fas fa-solid fa-phone"></i> 
                
              {/* <i className="text-orange-500 fas fa-solid fa-circle-phone"></i> */}
              <span className="lg:hidden inline-block ml-2">Book Now</span>
              </a>
            </li>

          </ul>
        </div>
      </div>
      <div>
    </div>
    </nav>
  );
}

const styles={
  iconCursor:{cursor:'pointer'},
  iconBackGround:{cursor:'pointer',
  height: 40,
  width:40,
   borderRadius:40,
  backgroundColor:'black',marginLeft:5}
}
