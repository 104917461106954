import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import emailjs from '@emailjs/browser'

export const sendEmail = createAsyncThunk('email/sendEmail', async (e)=>{
    const result = await emailjs.sendForm('service_s3imlj4', 'template_elrtwlr', e.target, 's7dx9L0Cst-eOySw_')
    // .then((result) => {
    //     console.log(result.text);
    //     e.target.reset();
    // }, (error) => {
    //     console.log(error.text);
    // });
    console.log("result is", result);
    if(result.text === 'OK'){
        return {result, e};
    }
})

const initialState = { 
    sendEmailSuccess: false,
    sendEmailLoading: false
} 

const sendEmailSlice = createSlice({
  name: 'email',
  initialState,
  reducers: {

  },
  extraReducers: {
      [sendEmail.pending]: (state) =>{
          console.log("pending mail");
          return {...state, sendEmailLoading: true, sendEmailSuccess: false}
      },
      [sendEmail.fulfilled]: (state, payload) =>{
        payload?.payload?.e?.target?.reset();
        return {...state, sendEmailLoading: false, sendEmailSuccess: true}
    },
    [sendEmail.rejected]: (state) =>{
        console.log("rejected mail");
        return {...state, sendEmailLoading: false, sendEmailSuccess: false}
    }
  }
})

export default sendEmailSlice.reducer