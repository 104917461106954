import PageTitle from "comps/PageTitle";
import React from "react";
import "../styles/index.css";
const PageNotFound = (props) => {
  const navigateToLanding = () => {
    props.history.push("/");
  };
  return (
    <>
      <div className="customApp">
        {/* <div className="title">
          <h1 className="cursor-pointer" onClick={navigateToLanding}>
            Arpita's Makeover
          </h1>
        </div> */}
        <PageTitle/>
      </div>
      <div class="flex items-center justify-center w-screen h-screen">
        <div class="px-4 lg:py-12">
          <div class="lg:gap-4 lg:flex">
            <div class="flex flex-col items-center justify-center md:py-24 lg:py-32">
              <h1
                style={{ fontSize: 39 }}
                class="font-bold text-red-600 text-9xl"
              >
                404
              </h1>
              <p class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
                <span class="text-red-500">Oops!</span> Page not found
              </p>
              <p class="mb-8 text-center text-gray-500 md:text-lg">
                The page you’re looking for doesn’t exist.
              </p>
              {/* <a
              href="/"
              class="px-6 py-2 text-sm font-semibold text-blue-800 bg-blue-100"
            >
              Go home
            </a> */}
              <a
                href="/"
                class="bg-transparent hover:bg-orange-500 text-orange-500 font-semibold hover:text-white p-4 border border-orange-500 hover:border-transparent rounded inline-block mt-5 cursor-pointer"
              >
                Go home
              </a>
            </div>
            <div class="mt-4">
              <img
                src="https://github.com/shyamalVixplor/MyPersonalImages/blob/main/amy-shamblen-xwM61TPMlYk-unsplash.jpg?raw=true"
                alt="img"
                className="shadow-lg rounded max-w-full mx-auto"
                    style={{ maxWidth: "550px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
