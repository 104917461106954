import React, { useEffect } from "react";

import Navbar from "components/Navbar.js";
import Footer from "components/Footer.js";
import AOS from "aos";
import { useDispatch, useSelector } from "react-redux";
import { sendEmail } from "store/sendEmailSlice";
import { Helmet } from "react-helmet-async";
import CustomerReview from "components/CustomerReview";
import { getAbout } from "store/configDataSlice";

export default function Landing(props) {
  const dispatch = useDispatch();
  const emailReducerState = useSelector((state) => state.emailReducer);
  const state = useSelector((state) => state);
  console.log("states are", state);
  const [userData, setUserData] = React.useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  useEffect(() => {
    AOS.init({
      delay: 200,
      duration: 1200,
      once: false,
    });
    dispatch(getAbout(`I am responsible for visually transforming people's'
    appearance via make-up, paint, wigs, and other accessories.
    I have the ability to determine the correct material and
    application according to lighting, setting and physical
    facial features.`))
  }, []);

  const viewImage = (data) => {
    props.history.push(`/imageDetails/${data}`);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(sendEmail(e));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <>
      <Helmet>
        <title>arpita's makeover</title>
        <meta
          name="description"
          content="Welcome to Arpita's Makeover. I am a professional makeup artist. My main motive is to provide the best makeup within a less time & budget."
        />
        <link rel="canonical" href="/" />
      </Helmet>
      <Navbar transparent daraProps={props} />
      <main>
        <div
          className="relative pt-16 pb-16 flex content-center items-center justify-center"
          style={{
            minHeight: "200vh",
          }}
        >
          <div
            className="backgroundImage absolute top-0 w-full h-full bg-top bg-cover"
            // style={{backgroundImage:"url('https://github.com/shyamalVixplor/MyPersonalImages/blob/main/bridal.jpg?raw=true')"}}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto" data-aos="fade-in">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div>
                  <h1 className="text-white font-semibold text-5xl">
                    Feel The{" "}
                    <span className="text-orange-500">Magic Touch</span>
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    {state?.appConfigReducer?.landingDesc}
                  </p>
                  {/* <a href="#" class="bg-transparent hover:bg-orange-500 text-orange-500 font-semibold hover:text-white p-4 border border-orange-500 hover:border-transparent rounded inline-block mt-5 cursor-pointer">
                      Download Brochure
                    </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* About Section */}
        <section id="about" className="relative py-20 bg-black text-white">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div
                className="w-full md:w-4/12 ml-auto mr-auto px-4"
                data-aos="fade-right"
              >
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src={require("assets/img/western15.jpeg").default}
                  // src="https://github.com/shyamalVixplor/MyPersonalImages/blob/main/ArpitasMakeup.jpg?raw=true"
                />
              </div>
              <div
                className="w-full md:w-5/12 ml-auto mr-auto px-4"
                data-aos="fade-left"
              >
                <div className="md:pr-12">
                  <small className="text-orange-500">
                    About My Makeup Studio
                  </small>
                  <h3 className="text-4xl uppercase font-bold">
                    Safe Makeup Touch
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed">
                   {state?.appConfigReducer?.makeupAbout}
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="font-semibold inline-block py-3 mr-3 text-orange-500">
                            <i className="fas fa-fingerprint fa-2x"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-xl">
                            The latest & greatest Makeup studio
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="font-semibold inline-block py-3 mr-3 text-orange-500">
                            {/* <i className="fas fa-hard-hat fa-2x"></i> */}
                            <i
                              class="fa fa-paint-brush fa-2x"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-xl">
                            Do your special day memorable with your special
                            appearance
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="font-semibold inline-block py-3 mr-3 text-orange-500">
                            <i className="fas fa-users fa-2x"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-xl">Budget friendly</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Makeup sections */}
        <section className="pt-20 pb-20">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold uppercase">My Work</h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  I'm here to get real with you about what makes you beautiful.
                  My passion for make-up drives me to create versatile looks
                  that take your natural beauty to the next level. The creation
                  of beauty is an art and we're those performing artists. Good
                  makeup means a good day!
                </p>
              </div>
            </div>
            {/* Photo Sections cards */}
            <div className="flex flex-wrap" style={{ marginBottom: 20 }}>
              {/* Card 1 */}
              <div
                className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
                data-aos="flip-right"
              >
                <div
                  className="px-6 cursor-pointer"
                  onClick={() => viewImage("Bridal")}
                >
                  <img
                    alt="..."
                    src={require("assets/img/ArpitasMakeoverNew/BridalTemplete.jpg").default}
                    className="shadow-lg rounded max-w-full mx-auto"
                    style={{  width: "280px",
                    maxWidth: "280px",
                    height: "391px",
                    maxHeight: "391px", }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Bridal</h5>
                    <button class="bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View All
                    </button>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div
                className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
                data-aos="flip-right"
              >
                <div
                  className="px-6 cursor-pointer"
                  onClick={() => viewImage("Reception")}
                >
                  <img
                    alt="..."
                    src={require("assets/img/makeup3.jpg").default}
                    className="shadow-lg rounded max-w-full mx-auto"
                    style={{
                      width: "280px",
                      maxWidth: "280px",
                      height: "391px",
                      maxHeight: "391px",
                    }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Reception</h5>
                    <button class="bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View All
                    </button>
                  </div>
                </div>
              </div>
              {/* Card 3 */}
              <div
                className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
                data-aos="flip-right"
              >
                <div
                  className="px-6 cursor-pointer"
                  onClick={() => viewImage("Western")}
                >
                  <img
                    alt="..."
                    src={require("assets/img/western15.png").default}
                    className="shadow-lg rounded max-w-full mx-auto"
                    style={{  width: "280px",
                    maxWidth: "280px",
                    height: "391px",
                    maxHeight: "391px", }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Western & Party</h5>
                    <button class="bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap">
              {/* Card 1 */}
              <div
                className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
                data-aos="flip-right"
              >
                <div
                  className="px-6 cursor-pointer"
                  onClick={() => viewImage("Hair")}
                >
                  <img
                    alt="..."
                    src={require("assets/img//ArpitasMakeoverNew/HairStyle.jpg").default}
                    className="shadow-lg rounded max-w-full mx-auto"
                    style={{  width: "280px",
                    maxWidth: "280px",
                    height: "391px",
                    maxHeight: "391px", }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Hair Style</h5>
                    <button class="bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View All
                    </button>
                  </div>
                </div>
              </div>
              {/* Card 2 */}
              <div
                className="w-full md:w-4/12 lg:mb-0 mb-12 px-4"
                data-aos="flip-right"
              >
                <div
                  className="px-6 cursor-pointer"
                  onClick={() => viewImage("Others")}
                >
                  <img
                    alt="..."
                    src={require("assets/img/western5.jpeg").default}
                    className="shadow-lg rounded max-w-full mx-auto"
                    style={{  width: "280px",
                    maxWidth: "280px",
                    height: "391px",
                    maxHeight: "391px", }}
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Others</h5>
                    <button class="bg-orange-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                      View All
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section> */}
        {/* <section className="pt-20 pb-20">
          <div className="container mx-auto px-4"> */}
        <CustomerReview/>
        {/* </div>
        </section> */}
{/* </section> */}
        {/* Contact Header Section */}
        <section className="pb-20 relative block bg-black text-white">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64 pb-20 pt-20">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white uppercase">
                  Contact Us
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4">
                  {/* Put the potentially record low maximum sea ice extent tihs year down to low ice.
                  According to the National Oceanic and Atmospheric Administration, Ted, Scambos. */}
                  Feel free to share your query with us
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Form */}
        <section className="relative block py-24 lg:pt-0 bg-black">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
              <div className="w-full lg:w-6/12 px-4">
                <div
                  className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300"
                  data-aos="fade-up-right"
                >
                  <div className="flex-auto p-5 lg:p-10 bg-orange-500 text-white">
                    <form onSubmit={handleSubmit}>
                      <h4 className="text-2xl font-semibold">
                        Want to work with us?
                      </h4>
                      <p className="leading-relaxed mt-1 mb-4">
                        Complete this form and we will get back to you in 24
                        hours.
                      </p>
                      <div className="relative w-full mb-3 mt-8">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          htmlFor="full-name"
                        >
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Full Name"
                          style={{
                            transition: "all .15s ease",
                            color: "black",
                          }}
                          onChange={handleChange}
                          id="name"
                          name="name"
                          required
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Email"
                          style={{ transition: "all .15s ease" }}
                          onChange={handleChange}
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          htmlFor="subject"
                        >
                          Subject
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Subject"
                          style={{ transition: "all .15s ease" }}
                          onChange={handleChange}
                          id="subject"
                          name="subject"
                          required
                        />
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-xs font-bold mb-2"
                          htmlFor="message"
                        >
                          Message
                        </label>
                        <textarea
                          rows="4"
                          cols="80"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Type a message..."
                          onChange={handleChange}
                          id="message"
                          name="message"
                          required
                        />
                      </div>
                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                          type="submit"
                          style={{ transition: "all .15s ease" }}
                          disabled={
                            emailReducerState.sendEmailLoading ? true : false
                          }
                        >
                          {emailReducerState.sendEmailLoading
                            ? "Please wait.."
                            : "Send Message"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
