import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCNojZDvzucfgCiO8rZXArKsArNefwueTE",
  authDomain: "arpitamakeover-d5ccf.firebaseapp.com",
  projectId: "arpitamakeover-d5ccf",
  storageBucket: "arpitamakeover-d5ccf.appspot.com",
  messagingSenderId: "391563396778",
  appId: "1:391563396778:web:11111b82a4724cc440b2d3",
  measurementId: "G-98E68WXS9X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };