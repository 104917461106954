export const reviewData = [
  {
    name: "Sayani Dutta",
    profession: "Professional Model",
    personReview:
      "Arpita is amazing!!! I hands down think she is one of the best makeup artist. She is extremely professional and knowledgeable. Made my makeup and skin look flawless, received so many compliments!!! Can’t imagine going to any other makeup artist other than her!",
    image:
      "https://github.com/shyamalVixplor/MyPersonalImages/blob/main/reviewImages/bridalReview%20(1).png?raw=true",
  },
  {
    name: "Dona",
    profession: "Professional Model",
    personReview:
      "Arpita did my make up for my wedding day and she did a phenomenal job. I absolutely loved her work! She made me look stunning for my wedding day and the look was natural like I wanted. She was professional and kind and even kept me calm the morning of the wedding! Anyone that hires her is in good hands! 💕",
    image:
      "https://github.com/shyamalVixplor/MyPersonalImages/blob/main/reviewImages/DonaReviewOriginal1%20(2).png?raw=true",
  },

  {
    name: "Indrani",
    profession: "Professional Model",
    personReview:
      "Excellent! Easy to communicate with and very responsive. She was prompt and professional, but most importantly she is a a highly skilled makeup artist. Her work was truly flawless! Would recommend without hesitation!",
    image:
      "https://github.com/shyamalVixplor/MyPersonalImages/blob/main/reviewImages/receptionReview1.png?raw=true",
  },
];
