import Navbar from "components/Navbar";
import React from "react";
import { withRouter } from "react-router-dom";

const Title = (props) => {
  const navigateToLanding = () => {
    props.history.push("/");
  };

  return (
    <div className="title cursor-pointer" onClick={navigateToLanding}>
      {/* <div style={{width:180, paddingLeft:60}}>
      <img
        src="https://github.com/shyamalVixplor/MyPersonalImages/blob/main/amy-shamblen-xwM61TPMlYk-unsplash.jpg?raw=true"
        alt="img"
        className="cursor-pointer" 
        style={{ maxWidth: "50px", alignSelf:'center' }}
      />
      </div> */}

      {/* <h1 className="cursor-pointer">
        Arpita's Makeover
      </h1> */}
      <h2>{props.imageType} Pictures</h2>
      <p>Feel the magic touch</p>
    </div>
  );
};

export default withRouter(Title);
